

import React from "react"
import styled from "styled-components"

const MainContent = () => {
  return (
    <Wrapper>
      <h3>Video blah blah</h3>
      <h3>Video blah blah</h3>
      <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus quibusdam nulla odio, qui impedit veniam quo ipsa suscipit, aliquid obcaecati rem nostrum nam illo consequatur laboriosam distinctio quaerat, repudiandae provident?</p>
      <h3>Video blah blah</h3>
      <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus quibusdam nulla odio, qui impedit veniam quo ipsa suscipit, aliquid obcaecati rem nostrum nam illo consequatur laboriosam distinctio quaerat, repudiandae provident?</p>
      <h3>Video blah blah</h3>

      <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus quibusdam nulla odio, qui impedit veniam quo ipsa suscipit, aliquid obcaecati rem nostrum nam illo consequatur laboriosam distinctio quaerat, repudiandae provident?</p>
      <h3>Video blah blah</h3>
      <h3>Video blah blah</h3>      <h3>Video blah blah</h3>
      <h3>Video blah blah</h3>      <h3>Video blah blah</h3>
      <h3>Video blah blah</h3>
      <h3>Video blah blah</h3>
      <h3>Video blah blah</h3>
      <h3>Video blah blah</h3>
      <h3>Video blah blah</h3>
      <h3>Video blah blah</h3>
      <h3>Video blah blah</h3>

    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 80px 20px 20px 100px;


`


export default MainContent