import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyCNwJ_fbgJ12Kcw0GPuw3J4SgcmvsVyzvg",
  authDomain: "nadinewieder-5bf84.firebaseapp.com",
  projectId: "nadinewieder-5bf84",
  storageBucket: "nadinewieder-5bf84.appspot.com",
  messagingSenderId: "636138974607",
  appId: "1:636138974607:web:ab2047d792dd4cb69d2ffa"
};

firebase.initializeApp(firebaseConfig)


const firestore = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()
const timestamp = firebase.firestore.Timestamp

export { firestore, auth, timestamp, storage }