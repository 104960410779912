
import styled from "styled-components"
import { ImPlay } from "react-icons/im"
import { Logo } from "./styleText"



export const YouTubeIcon = styled(ImPlay)`
  color: red;
  font-size: 1.5rem;
  position: relative;


`